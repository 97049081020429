import { ProductKeyDataPush } from '@/src/types/the-q-api';
import { LightstreamSubscription } from '@/src/types/vue-api';
import {
  LightStreamerProductSubscriptionMutationTypes,
  productNamespace,
  PUSH_PRODUCT_NAMESPACES,
  PUSH_SUBSCRIPTION_PRODUCT_GETTER,
  PUSH_UPDATE_PRODUCT,
} from '@/src/state/plugins/light-streamer/pushing-helper';
import { ItemUpdate } from 'lightstreamer-client-web';
import Vue from 'vue';
import { subscribe } from './subscribe';

const productAdapter = 'Citi-ProductKeyFigures';
enum KeyDataFields {
  Isin = 'Isin',
  KeyFiguresInnerValue = 'KeyFiguresInnerValue',

  KeyFiguresIndependentMaximumAmount = 'KeyFiguresIndependentMaximumAmount',
  KeyFiguresIndependentMaximumPayoff = 'KeyFiguresIndependentMaximumPayoff',
  KeyFiguresIndependentBonusAmount = 'KeyFiguresIndependentBonusAmount',
  KeyFiguresIndependentBonusPayoff = 'KeyFiguresIndependentBonusPayoff',
  KeyFiguresIndependentDaysToMaturity = 'KeyFiguresIndependentDaysToMaturity',
  KeyFiguresIndependentYearsToMaturity = 'KeyFiguresIndependentYearsToMaturity',

  KeyFiguresDistancesToStrikesDistanceToStrike = 'KeyFiguresDistancesToStrikesDistanceToStrike',
  KeyFiguresDistancesToStrikesDistanceToCap = 'KeyFiguresDistancesToStrikesDistanceToCap',
  KeyFiguresDistancesToStrikesDistanceToStrikeRelToSpot = 'KeyFiguresDistancesToStrikesDistanceToStrikeRelToSpot',

  KeyFiguresDistancesToBarriersAndKnockoutsDistanceToBarrier = 'KeyFiguresDistancesToBarriersAndKnockoutsDistanceToBarrier',
  KeyFiguresDistancesToBarriersAndKnockoutsDistanceToBarrierRelToSpot = 'KeyFiguresDistancesToBarriersAndKnockoutsDistanceToBarrierRelToSpot',
  KeyFiguresDistancesToBarriersAndKnockoutsDistanceToKnockout = 'KeyFiguresDistancesToBarriersAndKnockoutsDistanceToKnockout',
  KeyFiguresDistancesToBarriersAndKnockoutsDistanceToKnockoutRelToSpot = 'KeyFiguresDistancesToBarriersAndKnockoutsDistanceToKnockoutRelToSpot',

  KeyFiguresCertPricePremium = 'KeyFiguresCertPricePremium',
  KeyFiguresCertPriceDiscount = 'KeyFiguresCertPriceDiscount',
  KeyFiguresCertPriceSidewayYield = 'KeyFiguresCertPriceSidewayYield',
  KeyFiguresCertPriceSidewayYieldPerAnnum = 'KeyFiguresCertPriceSidewayYieldPerAnnum',
  KeyFiguresCertPriceLeverage = 'KeyFiguresCertPriceLeverage',
  KeyFiguresCertPriceOutperformancePoint = 'KeyFiguresCertPriceOutperformancePoint',

  KeyFiguresCertPriceMaximumYield = 'KeyFiguresCertPriceMaximumYield',
  KeyFiguresCertPriceMaximumYieldPerAnnum = 'KeyFiguresCertPriceMaximumYieldPerAnnum',
  KeyFiguresCertPriceBidAskSpreadsSpread = 'KeyFiguresCertPriceBidAskSpreadsSpread',
  KeyFiguresCertPriceBidAskSpreadsSpreadRelToBid = 'KeyFiguresCertPriceBidAskSpreadsSpreadRelToBid',
  KeyFiguresCertPriceBidAskSpreadsSpreadHom = 'KeyFiguresCertPriceBidAskSpreadsSpreadHom',
  KeyFiguresCertPriceBreakEven = 'KeyFiguresCertPriceBreakEven',
  KeyFiguresCertPriceBonusYield = 'KeyFiguresCertPriceBonusYield',

  KeyFiguresBlackScholesQuantitiesDailyTheta = 'KeyFiguresBlackScholesQuantitiesDailyTheta',
  KeyFiguresBlackScholesQuantitiesImpliedVolatility = 'KeyFiguresBlackScholesQuantitiesImpliedVolatility',
  KeyFiguresBlackScholesQuantitiesBarrierHitProbability = 'KeyFiguresBlackScholesQuantitiesBarrierHitProbability',
  KeyFiguresBlackScholesQuantitiesTotalLossProbability = 'KeyFiguresBlackScholesQuantitiesTotalLossProbability',
  KeyFiguresBlackScholesQuantitiesMaximumYieldProbability = 'KeyFiguresBlackScholesQuantitiesMaximumYieldProbability',
  KeyFiguresBlackScholesQuantitiesOmega = 'KeyFiguresBlackScholesQuantitiesOmega',
  KeyFiguresBlackScholesQuantitiesDeltaOverRatio = 'KeyFiguresBlackScholesQuantitiesDeltaOverRatio',
  KeyFiguresBlackScholesQuantitiesGammaOverRatio = 'KeyFiguresBlackScholesQuantitiesGammaOverRatio',
  KeyFiguresBlackScholesQuantitiesPercentageVega = 'KeyFiguresBlackScholesQuantitiesPercentageVega',

  KeyFiguresBlackScholesQuantitiesOneDayDailyTheta = 'KeyFiguresBlackScholesQuantitiesOneDayDailyTheta',
  KeyFiguresBlackScholesQuantitiesOneDayImpliedVolatility = 'KeyFiguresBlackScholesQuantitiesOneDayImpliedVolatility',
  KeyFiguresBlackScholesQuantitiesOneDayBarrierHitProbability = 'KeyFiguresBlackScholesQuantitiesOneDayBarrierHitProbability',
  KeyFiguresBlackScholesQuantitiesOneDayTotalLossProbability = 'KeyFiguresBlackScholesQuantitiesOneDayTotalLossProbability',
  KeyFiguresBlackScholesQuantitiesOneDayMaximumYieldProbability = 'KeyFiguresBlackScholesQuantitiesOneDayMaximumYieldProbability',
  KeyFiguresBlackScholesQuantitiesOneDayOmega = 'KeyFiguresBlackScholesQuantitiesOneDayOmega',
  KeyFiguresBlackScholesQuantitiesOneDayDeltaOverRatio = 'KeyFiguresBlackScholesQuantitiesOneDayDeltaOverRatio',
  KeyFiguresBlackScholesQuantitiesOneDayGammaOverRatio = 'KeyFiguresBlackScholesQuantitiesOneDayGammaOverRatio',
  KeyFiguresBlackScholesQuantitiesOneDayPercentageVega = 'KeyFiguresBlackScholesQuantitiesOneDayPercentageVega',
  Timestamp = 'Timestamp',
}

function tryParseFloat(value: string | null): number | null {
  if (value === null) return null;
  return parseFloat(value);
}

function tryParseInt(value: string | null): number | null {
  if (value === null) return null;
  return parseInt(value);
}

function mapPushFields(pushItem): ProductKeyDataPush {
  const result = {
    isin: pushItem.getValue(KeyDataFields.Isin),
    ttmKeyData: {
      timestamp: pushItem.getValue(KeyDataFields.Timestamp),
      innerValue: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresInnerValue)),
      independent: {
        maximumAmount: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresIndependentMaximumAmount)),
        maximumPayoff: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresIndependentMaximumPayoff)),
        bonusAmount: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresIndependentBonusAmount)),
        bonusPayoff: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresIndependentBonusPayoff)),
        daysToMaturity: tryParseInt(pushItem.getValue(KeyDataFields.KeyFiguresIndependentDaysToMaturity)),
        yearsToMaturity: tryParseInt(pushItem.getValue(KeyDataFields.KeyFiguresIndependentYearsToMaturity)),
      },
      distancesToStrikes: {
        distanceToStrike: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresDistancesToStrikesDistanceToStrike)),
        distanceToCap: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresDistancesToStrikesDistanceToCap)),
        distanceToStrikeRelToSpot: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresDistancesToStrikesDistanceToStrikeRelToSpot)
        ),
      },
      distancesToBarriersAndKnockouts: {
        distanceToBarrier: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresDistancesToBarriersAndKnockoutsDistanceToBarrier)
        ),
        distanceToBarrierRelToSpot: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresDistancesToBarriersAndKnockoutsDistanceToBarrierRelToSpot)
        ),
        distanceToKnockout: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresDistancesToBarriersAndKnockoutsDistanceToKnockout)
        ),
        distanceToKnockoutRelToSpot: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresDistancesToBarriersAndKnockoutsDistanceToKnockoutRelToSpot)
        ),
      },
      certPrice: {
        maximumYield: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceMaximumYield)),
        maximumYieldPerAnnum: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceMaximumYieldPerAnnum)),
        bidAskSpreadsSpread: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceBidAskSpreadsSpread)),
        bidAskSpreadsSpreadRelToBid: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresCertPriceBidAskSpreadsSpreadRelToBid)
        ),
        bidAskSpreadsSpreadHom: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresCertPriceBidAskSpreadsSpreadHom)
        ),
        breakEven: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceBreakEven)),
        bonusYield: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceBonusYield)),
      },
      spotAndCertPrice: {
        premium: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPricePremium)),
        discount: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceDiscount)),
        sidewayYield: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceSidewayYield)),
        sidewayYieldPerAnnum: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceSidewayYieldPerAnnum)),
        leverage: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceLeverage)),
        outperformancePoint: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresCertPriceOutperformancePoint)),
      },
      blackScholesQuantities: {
        dailyTheta: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesDailyTheta)),
        impliedVolatility: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesImpliedVolatility)
        ),
        barrierHitProbability: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesBarrierHitProbability)
        ),
        totalLossProbability: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesTotalLossProbability)
        ),
        maximumYieldProbability: tryParseFloat(
          pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesMaximumYieldProbability)
        ),
        omega: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesOmega)),
        deltaOverRatio: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesDeltaOverRatio)),
        gammaOverRatio: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesGammaOverRatio)),
        percentageVega: tryParseFloat(pushItem.getValue(KeyDataFields.KeyFiguresBlackScholesQuantitiesPercentageVega)),
      },
    },
  } as ProductKeyDataPush;
  return result as ProductKeyDataPush;
}

export default (client, store) =>
  store.subscribe((mutation) => {
    return subscribe({
      client,
      mutation,
      fields: Object.keys(KeyDataFields),
      adapterSet: productAdapter,
      namespaces: PUSH_PRODUCT_NAMESPACES,
      subscriptionMutationTypes: LightStreamerProductSubscriptionMutationTypes,
      pushItemSubscriptionGetter: productNamespace + PUSH_SUBSCRIPTION_PRODUCT_GETTER,
      onUpdate: function (pushItem: ItemUpdate, pushSubscriptions: { [key: string]: boolean }) {
        const productPricePush = mapPushFields(pushItem);
        Vue.$log.debug('Lightstreamer product keydata push', productPricePush, 'push subscriptions', pushSubscriptions);
        PUSH_PRODUCT_NAMESPACES.forEach((n) =>
          store.commit(n + PUSH_UPDATE_PRODUCT, { productPricePush, pushSubscriptions })
        );
      },
    } as LightstreamSubscription);
  });
