import Vue from 'vue';
import Vuex from 'vuex';
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules';

import modules from './modules';

import lightStreamerPlugin from '@state/plugins/light-streamer';
import vuexLocalStoragePlugin from '@state/plugins/persist';
import sharedMutationPlugin from '@state/plugins/shared-mutations';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',

  plugins: [lightStreamerPlugin, vuexLocalStoragePlugin, sharedMutationPlugin],
});

export default store;

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init');
