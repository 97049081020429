import { ConnectionSharing, LightstreamerClient } from 'lightstreamer-client-web';

import underlyingSubscriber from './underlying';
import productSubscriber from './product-prices';
import productKeyDataSubscriber from './product-keydata';

const client = new LightstreamerClient(
  process.env.VUE_APP_LIGHTSTREAMER_URL,
  process.env.VUE_APP_LIGHTSTREAMER_ADAPTER_SET
);

client.enableSharing(new ConnectionSharing('CitiLightstreamer', 'ATTACH', 'CREATE'));
client.connect();

const lightStreamerPlugin = (store) =>
  [underlyingSubscriber, productSubscriber, productKeyDataSubscriber].map((subscriber) => subscriber(client, store));
export default lightStreamerPlugin;
