<template>
  <div class="mt-4">
    <b-container class="px-3 px-md-4 pb-4">
      <div v-if="isSharedWebsite">
        <b-row>
          <b-col>
            <h3 class="mb-2"><slot name="website-selection-header"></slot></h3
          ></b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="inner pr-0 pr-md-3">
              <slot name="website-selection-description"></slot>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group class="bg-transparent border-0">
              <b-form-radio-group
                v-model="currentWebsite"
                id="website-group"
                :options="getWebsiteOptions()"
                name="website-options"
                stacked
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <slot name="disclaimer-block" :current-website="currentWebsite"></slot>
    </b-container>
    <ad-cookie-consent
      @consent="onConsent"
      canReject
      :cookieconsentversion="cookieconsentversion"
      :is-accept-buttons-enabled="isSingleWebsiteOrSelected"
    >
      <template v-slot:cookie-consent-header><slot name="cookie-cookie-consent-header"></slot></template>
      <template #cookie-consent><slot name="cookie-cookie-consent"></slot></template>
      <template #cookie-consent-acceptall><slot name="disclaimer-cookie-consent-acceptall"></slot></template>
      <template #cookie-consent-acceptselected>
        <slot name="disclaimer-cookie-consent-acceptselected"></slot>
      </template>
      <template #cookie-consent-reject><slot name="disclaimer-cookie-consent-reject"></slot></template>
      <template #cookie-technical><slot name="cookie-cookie-technical"></slot></template>
      <template #cookie-marketing><slot name="cookie-cookie-marketing"></slot></template>
      <template #cookie-comfort><slot name="cookie-cookie-comfort"></slot></template>
      <template #cookie-third><slot name="cookie-cookie-third"></slot></template>
      <template #cookie-remember-selection><slot name="cookie-cookie-remember-selection"></slot></template>
    </ad-cookie-consent>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { CookiePageSettings } from '@/src/types/episerver-api';
import { SelectOption } from '@/src/types/vue-api';
import CookieManager, { CookieWebSelection } from '@/src/utils/cookie';

@Component
export default class AdDisclaimerModal extends Vue {
  private cookieManager!: CookieManager;
  @Inject() settingsProperty!: CookiePageSettings;

  @Prop({ default: '#' })
  rejecturl!: string;

  @Prop({ default: undefined })
  cookieconsentversion: string | undefined;

  currentWebsite: string | null = null;
  isSharedWebsite = false;

  get isSingleWebsiteOrSelected(): boolean {
    return !this.isSharedWebsite || this.currentWebsite !== null;
  }

  created(): void {
    this.cookieManager = new CookieManager(this.$cookies);
  }

  mounted(): void {
    if (this.settingsProperty.disclaimerItemBlockSettings.length > 1) {
      this.isSharedWebsite = true;
    }
    if (this.settingsProperty.disclaimerItemBlockSettings.length == 1) {
      this.currentWebsite = this.settingsProperty.disclaimerItemBlockSettings[0].websiteKey;
    }
  }

  onConsent(state: boolean): void {
    if (state) {
      this.saveCookie(state);
      this.$bvModal.hide('ad-disclaimer-modal');

      //load data again after cookie setting.
      document.location.reload();
    } else {
      document.location.href = this.rejecturl;
    }
  }

  private getWebsiteOptions(): Array<SelectOption> {
    return this.settingsProperty.disclaimerItemBlockSettings.map((_) => new SelectOption(_.websiteKey, _.websiteName));
  }

  private saveCookie(remember: boolean): void {
    if (!this.currentWebsite) {
      return;
    }
    const cookieSetting = this.settingsProperty.disclaimerItemBlockSettings.find(
      (_) => _.websiteKey === this.currentWebsite
    );
    if (!(cookieSetting && cookieSetting.cookieName)) {
      return;
    }

    this.cookieManager.saveSelectedWebsite(
      new CookieWebSelection(cookieSetting.cookieName, cookieSetting.cookieValue),
      remember
    );
  }
}
</script>
<style lang="scss">
.cookie-disclaimer-footer {
  justify-content: left !important;
  background: $marine-blue;
  text-align: left;

  a {
    margin-right: 1rem;
    color: $white;
  }
}
.alert-disclaimer {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  .inner {
    position: relative;
    max-height: rem(162);
    overflow-y: auto;
  }

  .readall {
    display: none;
    cursor: pointer;
    color: $secondary;
  }
}

@include media-breakpoint-down(sm) {
  .alert-disclaimer {
    border-radius: 0;
    max-width: none !important;
    overflow-y: hidden;

    .inner {
      height: auto;
      max-height: auto;
      overflow-y: hidden;
    }

    .readall {
      display: block;
      position: absolute;
      bottom: rem(-5);
      background: $pale-blue;
      background: linear-gradient(0deg, $pale-blue 0%, $pale-blue 40%, rgba(255, 255, 255, 0) 100%);
      padding-top: rem(35);
      padding-bottom: rem(2);
      width: 100%;
      text-align: center;
      color: $bright-sky-blue;
    }

    &.full {
      height: auto;
      max-height: none;

      .inner {
        height: auto;
        max-height: none;
      }

      .readall {
        display: none;
      }
    }
  }
}
</style>
