import { UnderlyingPricePush } from '@/src/types/the-q-api';
import { LightstreamSubscription } from '@/src/types/vue-api';
import {
  LightStreamerUnderlyingSubscriptionMutationTypes,
  PUSH_SUBSCRIPTION_UNDERLYING_GETTER,
  PUSH_UNDERLYING_NAMESPACES,
  PUSH_UPDATE_UNDERLYING,
  underlyingNamespace,
} from '@/src/state/plugins/light-streamer/pushing-helper';
import { ItemUpdate } from 'lightstreamer-client-web';
import Vue from 'vue';
import { subscribe } from './subscribe';

const underlyingAdapter = 'Citi-UnderlyingPrices';
const fields = ['TimeStamp', 'Isin', 'LastCurrencyCode', 'LastCurrencyAmount', 'ChangePercent', 'ChangeAbsolute'];

export default (client, store) =>
  store.subscribe((mutation) => {
    return subscribe({
      client,
      mutation,
      fields: fields,
      adapterSet: underlyingAdapter,
      namespaces: PUSH_UNDERLYING_NAMESPACES,
      subscriptionMutationTypes: LightStreamerUnderlyingSubscriptionMutationTypes,
      pushItemSubscriptionGetter: underlyingNamespace + PUSH_SUBSCRIPTION_UNDERLYING_GETTER,
      onUpdate: function (pushItem: ItemUpdate, pushSubscriptions: { [key: string]: boolean }) {
        const underlyingPricePush = {
          isin: pushItem.getValue('Isin'),
          last: {
            amount: parseFloat(pushItem.getValue('LastCurrencyAmount')),
            currencyCode: pushItem.getValue('LastCurrencyCode'),
          },
          changePercentage: parseFloat(pushItem.getValue('ChangePercent')),
          changeAbsolute: {
            amount: parseFloat(pushItem.getValue('ChangeAbsolute')),
            currencyCode: pushItem.getValue('LastCurrencyCode'),
          },
          timeStamp: pushItem.getValue('TimeStamp'),
        } as UnderlyingPricePush;

        Vue.$log.debug('Lightstreamer underlying push', underlyingPricePush, 'push subscriptions', pushSubscriptions);
        PUSH_UNDERLYING_NAMESPACES.forEach((n) =>
          store.commit(n + PUSH_UPDATE_UNDERLYING, { underlying: underlyingPricePush, pushSubscriptions })
        );
      },
    } as LightstreamSubscription);
  });
