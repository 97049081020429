import Vue from 'vue';
import { SubscriptionPush } from '@/src/types/the-q-api';
import { LightstreamSubscription, MutationPushPayloadHolder } from '@/src/types/vue-api';
import { ItemUpdate, Subscription } from 'lightstreamer-client-web';
import { isMutationPushPayloadHolder } from '@/src/types/type-guards';
import store from '../../store';

const keyCountUpdateThrottleThreshold = parseInt(process.env.VUE_APP_LIGHTSTREAMER_KEY_COUNT_UPDATE_THROTTLE_THRESHOLD);
const updateThrottleFactor = parseInt(process.env.VUE_APP_LIGHTSTREAMER_UPDATE_THROTTLE_FACTOR);

const subscriptions = new Map<string, Subscription>();
const getKey = function (payload: SubscriptionPush) {
  return payload.origin.cultureId
    ? `${payload.origin.providerId}:${payload.origin.timeZoneId}:${payload.isin}:${payload.origin.cultureId}`
    : `${payload.origin.providerId}:${payload.origin.timeZoneId}:${payload.isin}`;
};

export function subscribe(lightstreamSubscription: LightstreamSubscription) {
  if (lightstreamSubscription.namespaces.some((n) => lightstreamSubscription.mutation.type.startsWith(n))) {
    const subscriptionCashKey = lightstreamSubscription.mutation.type + lightstreamSubscription.adapterSet;

    if (isMutationPushPayloadHolder(lightstreamSubscription.mutation.payload)) {
      Object.keys(lightstreamSubscription.subscriptionMutationTypes).forEach(function (mutationType) {
        if (lightstreamSubscription.mutation.type.endsWith(mutationType)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const keys = (lightstreamSubscription.mutation.payload as MutationPushPayloadHolder<any, SubscriptionPush>)
            .getSubscriptionPushItems(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (lightstreamSubscription.mutation.payload as MutationPushPayloadHolder<any, SubscriptionPush>).holder
            )
            .map((p) => getKey(p));

          const onItemUpdate = function (itemUpdate: ItemUpdate) {
            lightstreamSubscription.onUpdate(
              itemUpdate,
              store.getters[lightstreamSubscription.pushItemSubscriptionGetter]
            );
          };

          const subscription = new Subscription('MERGE', keys, lightstreamSubscription.fields);

          subscription.setDataAdapter(lightstreamSubscription.adapterSet);
          subscription.setRequestedSnapshot('yes');
          const frequency = keys.length > keyCountUpdateThrottleThreshold ? 1 / updateThrottleFactor : 1;
          subscription.setRequestedMaxFrequency(frequency);
          subscription.addListener({
            onItemUpdate: onItemUpdate,
          });

          if (subscriptions.has(subscriptionCashKey)) {
            const sb = subscriptions.get(subscriptionCashKey);
            Vue.$log.debug('Lightstreamer unsubscribe', subscriptionCashKey, sb);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            lightstreamSubscription.client.unsubscribe(sb!);
          }

          subscriptions.set(subscriptionCashKey, subscription);

          Vue.$log.debug('Lightstreamer subscribe', subscriptionCashKey, keys);
          lightstreamSubscription.client.subscribe(subscription);
        }
      });
    } else {
      if (subscriptions.has(subscriptionCashKey)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lightstreamSubscription.client.unsubscribe(subscriptions.get(subscriptionCashKey)!);
      }
    }
  }
}
