<template>
  <div class="ce-document-search d-flex align-items-center">
    <!-- Search input -->
    <div class="ce-header-quicksearch mr-2 py-0">
      <b-input-group class="py-0 align-items-center">
        <b-input-group-append class="ce-header-quicksearch__icon fs-18 py-0">
          <ad-icon-search></ad-icon-search>
        </b-input-group-append>
        <ad-global-search :showOnlyProductsInSearch="showOnlyProductsInSearch" ref="globalSearch" />
      </b-input-group>
    </div>
    <button class="btn btn-secondary py-1 fs-18" @click="onClickSearchButton">
      <ad-icon-search></ad-icon-search>
      <slot name="document-search-button-text"></slot>
    </button>
  </div>
</template>

<script lang="ts">
import AdGlobalSearch from '@/src/components/molecules/ad-global-search/index.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CeDocumentSearch extends Vue {
  protected showOnlyProductsInSearch = true;
  async onClickSearchButton() {
    await (this.$refs?.globalSearch as AdGlobalSearch)?.rapidSearch?.();
  }
}
</script>

<style lang="scss">
.ce-document-search {
  .v-select {
    flex: 1 1 0;
  }
}
</style>
