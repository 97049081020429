import { Controller, ProductSubTypeBo, ProductTypeBo, RoutingParameters } from '@/src/types/enumerations';
import qs from 'qs';
import { PageRoutes } from '../types/episerver-api';
import { BestMatchSearchRequestFilter, ProductSearchParametersFilter, Route } from '../types/vue-api';
import { getCurrentCulture } from './culture-helper';
import { parseQueryObject } from './query-param-helper';

export const UrlSubRoutes = {
  EpiServerApi: '/api',
  TheQApi: '/citi/v1/theq/api',
};

export function getRootUrl(): string {
  return `${location.origin}`;
}

export function getProtocol(): string {
  return `${location.protocol}`;
}

export function getTheQApiCultureCodeUrl(
  cultureCode: string,
  controller: Controller,
  action: string | undefined = undefined
): string {
  return `${getRootUrl()}${UrlSubRoutes.TheQApi}/${Controller[controller]}/${cultureCode}/${action}`;
}

export function getTheQApiUrl(controller: Controller, action: string): string {
  const cultureCode = getCurrentCulture();
  return getTheQApiCultureCodeUrl(cultureCode, controller, action);
}

export function getEpiserverApiUrl(controller: Controller, action: string): string {
  const cultureCode = getCurrentCulture();
  return `${getRootUrl()}${UrlSubRoutes.EpiServerApi}/${cultureCode}/${Controller[controller]}/${action}`;
}

/**
 *
 * Page Links
 *
 */

export function getEpiserverPageUrl(urlWithRouteParameter: string, routes: Array<Route>): string {
  routes.forEach((rp) => {
    urlWithRouteParameter = urlWithRouteParameter.replace(`{${rp.route.toString()}}`, encodeURIComponent(rp.parameter));
  });

  return urlWithRouteParameter;
}

/**
 *
 * External Links
 *
 */

/**
 * Youtube Embedded
 * @param youtubeId
 * @param options
 * @returns string
 */
export function getYoutubeUrl(youtubeId: string, options?: { autoPlay?: boolean; enableJsAPI?: boolean }): string {
  // eslint-disable-next-line
  const queryParams = {} as any;
  if (options?.autoPlay) queryParams.autoplay = 1;
  if (options?.enableJsAPI) queryParams.enablejsapi = 1;
  queryParams.origin = getRootUrl();

  const query = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `https://www.youtube.com/embed/${youtubeId}/?${query}`;
}

/**
 * Youtube IFrame
 * @returns string
 */
export function getYoutubeIFrameAPIUrl(): string {
  return `https://www.youtube.com/iframe_api`;
}

export function getYoutubeThumbnail(youtubeId: string): string {
  return `${getProtocol()}//img.youtube.com/vi/${youtubeId}/mqdefault.jpg`;
}

/**
 * Twitter
 * @param config
 * @returns string
 */
export function getTwitterShareIntentURL(config: { urlToShare: string; textToShare?: string }): string {
  return (
    'https://twitter.com/intent/tweet?text=' +
    encodeURIComponent(config?.textToShare ?? '') +
    encodeURIComponent('\n\n') +
    encodeURIComponent(config.urlToShare)
  );
}

/**
 * Facebook
 * @param config
 * @returns string
 */
export function getFacebookShareIntentURL(config: { urlToShare: string; appId: string }): string {
  return (
    'https://www.facebook.com/dialog/share?app_id=' +
    encodeURIComponent(config.appId) +
    '&display=page&href=' +
    encodeURIComponent(config.urlToShare)
  );
}

function encodeName(name: string) {
  const toUnderscoreSymbols = new RegExp("[ &+'?(),;=$/.]", 'g');
  return name.replace(toUnderscoreSymbols, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function shouldItemBeSerializiedInQuery(prefix: string, value: any) {
  switch (prefix) {
    case 'defaultUnderyling':
    case 'underlyingIsins':
    case 'resetSortOrders':
    case 'productType':
    case 'isRequestWithoutUnderlyingWhenNoResult':
      return;
    case 'pageNumber':
      if (value === 1) value = undefined;
      return value;
    case 'pageSize':
      if (value === Number.parseInt(process.env.VUE_APP_DEFAULT_PAGE_SIZE)) value = undefined;
      return value;
    case 'isAddColumnDistributionFee':
      if (!value) value = undefined;
      return value;
    default:
      return value;
  }
}

/**
 * Checks if the given path is absolute or relative and converts to absolute if path is relative
 * @param path
 * @returns returns path if it is absolute or creates absolute from given path
 */
function toAbsolutePath(path: string): string {
  if (/^http[s]?:.*/.test(path)) {
    return path;
  }

  return window.location.origin + path;
}

export function tryCreateProductSearchLink(
  pageRoutes: PageRoutes,
  productType: ProductTypeBo | undefined | null = undefined,
  params: ProductSearchParametersFilter | undefined = undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additonalQueryFilterFunc = (prefix: string, value: any) => value
): string | null {
  if (!pageRoutes.productSearchTypeUrlSegments) {
    return null;
  }
  let pageUrl = pageRoutes.productSearchTypeUrlSegments[productType ?? ''];
  if (!pageUrl) {
    return null;
  }

  if (!params) {
    return toAbsolutePath(pageUrl);
  }

  const queryString = qs.stringify(params, {
    skipNulls: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter: function (prefix: string, value: any) {
      value = shouldItemBeSerializiedInQuery(prefix, value);

      if (value !== undefined) value = additonalQueryFilterFunc(prefix, value);

      return value;
    },
  });
  if (queryString) pageUrl += '?' + queryString;

  return toAbsolutePath(pageUrl);
}

export function tryCreateProductLink(
  pageRoutes: PageRoutes,
  productType: ProductTypeBo,
  underlyingName: string,
  isinOrNsin: string
): string | null {
  if (pageRoutes.productDetailTypeUrlSegments) {
    const productUrlSegment = pageRoutes.productDetailTypeUrlSegments[productType];

    if (!productUrlSegment) {
      return null;
    }

    return toAbsolutePath(
      getEpiserverPageUrl(productUrlSegment, [
        { route: RoutingParameters.UnderlyingName, parameter: encodeName(underlyingName) },
        { route: RoutingParameters.IsinOrNsin, parameter: isinOrNsin },
      ])
    );
  }

  return null;
}

export function tryCreateUnderlyingLink(pageRoutes: PageRoutes, isinOrNsin: string, name: string): string | null {
  if (!pageRoutes.underlyingDetailUrlSegments) {
    return null;
  }

  return toAbsolutePath(
    getEpiserverPageUrl(pageRoutes.underlyingDetailUrlSegments, [
      { route: RoutingParameters.Isin, parameter: isinOrNsin },
      { route: RoutingParameters.UnderlyingName, parameter: encodeName(name) },
    ])
  );
}

export function getImageResizerUrl(
  urlWithParmeterPlaceholder: string,
  width: number,
  height: number
): string | undefined {
  return getEpiserverPageUrl(urlWithParmeterPlaceholder, [
    { route: RoutingParameters.Width, parameter: width.toString() },
    { route: RoutingParameters.Height, parameter: height.toString() },
  ]);
}

export function redirectToSearchProducts(
  underlyingIsin: string | null,
  pageRoutes: PageRoutes,
  openInParent: boolean = false,
  productType: ProductTypeBo | null = null,
  productSubType: ProductSubTypeBo | null = null
): void {
  const searchFilter = new ProductSearchParametersFilter();
  searchFilter.underlyingIsinOrIsins = underlyingIsin;
  searchFilter.subType = productSubType;
  const redirectUrl = tryCreateProductSearchLink(pageRoutes, productType, searchFilter);

  if (!redirectUrl) {
    return;
  }

  if (openInParent) {
    window.parent.location.href = redirectUrl;
  } else {
    window.location.href = redirectUrl;
  }
}

export function tryCreateBestMatchSearchLink(pageUrl: string, filter: BestMatchSearchRequestFilter): string {
  const queryString = qs.stringify(filter, {
    skipNulls: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filter: function (prefix: string, value: any) {
      switch (prefix) {
        case 'keyDataField':
        case 'keyDataValue':
        case 'numberOfItems':
          return;
        default:
          return value;
      }
    },
  });

  if (queryString) pageUrl += '?' + queryString;

  return pageUrl;
}

export function getParamFromUrl<T>(name: Extract<keyof T, string>): any | null {
  const searchQueryParams = parseQueryObject(qs.parse(window.location.search, { ignoreQueryPrefix: true }));
  return searchQueryParams[name] ? searchQueryParams[name] : null;
}
