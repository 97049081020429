import { ProductPricePush } from '@/src/types/the-q-api';
import { LightstreamSubscription } from '@/src/types/vue-api';
import {
  LightStreamerProductSubscriptionMutationTypes,
  productNamespace,
  PUSH_PRODUCT_NAMESPACES,
  PUSH_SUBSCRIPTION_PRODUCT_GETTER,
  PUSH_UPDATE_PRODUCT,
} from '@/src/state/plugins/light-streamer/pushing-helper';
import { ItemUpdate } from 'lightstreamer-client-web';
import Vue from 'vue';
import { subscribe } from './subscribe';

const productAdapter = 'Citi-ProductPrices';
const fields = [
  'Isin',
  'Ask',
  'Bid',
  'AskSize',
  'BidSize',
  'Timestamp',
  'Identifier.Isin',
  'ChangeAbsolute',
  'ChangePercent',
  'CurrencyCode',
  'DailyHigh',
  'DailyLow',
];

export default (client, store) =>
  store.subscribe((mutation) => {
    return subscribe({
      client,
      mutation,
      fields: fields,
      adapterSet: productAdapter,
      namespaces: PUSH_PRODUCT_NAMESPACES,
      subscriptionMutationTypes: LightStreamerProductSubscriptionMutationTypes,
      pushItemSubscriptionGetter: productNamespace + PUSH_SUBSCRIPTION_PRODUCT_GETTER,
      onUpdate: function (pushItem: ItemUpdate, pushSubscriptions: { [key: string]: boolean }) {
        // sometimes pushes will be received empty
        if (!pushItem.getValue('Isin')) return;

        let productPricePush;
        try {
          const currencyCode = pushItem.getValue('CurrencyCode');
          productPricePush = {
            isin: pushItem.getValue('Isin'),
            price: {
              ask: {
                amount: parseFloat(pushItem.getValue('Ask')),
                currencyCode: currencyCode,
              },
              bid: {
                amount: parseFloat(pushItem.getValue('Bid')),
                currencyCode: currencyCode,
              },
              changeAbsolute: {
                amount: parseFloat(pushItem.getValue('ChangeAbsolute')),
                currencyCode: currencyCode,
              },
              changePercent: parseFloat(pushItem.getValue('ChangePercent')),
              askSize: parseInt(pushItem.getValue('AskSize')),
              bidSize: parseInt(pushItem.getValue('BidSize')),
              timeStamp: pushItem.getValue('Timestamp'),
            },
            performance: {
              dailyHigh: {
                amount: parseFloat(pushItem.getValue('DailyHigh')),
                currencyCode: currencyCode,
              },
              dailyLow: {
                amount: parseFloat(pushItem.getValue('DailyLow')),
                currencyCode: currencyCode,
              },
            },
          } as ProductPricePush;

          PUSH_PRODUCT_NAMESPACES.forEach((n) =>
            store.commit(n + PUSH_UPDATE_PRODUCT, { productPricePush, pushSubscriptions })
          );
          Vue.$log.debug('Lightstreamer product price push', productPricePush, 'push subscriptions', pushSubscriptions);
        } catch (e) {
          Vue.$log.error('Could not parse product price push', pushItem, e);
        }
      },
    } as LightstreamSubscription);
  });
